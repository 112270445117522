import Layzr from './layzr.min'
import ga from './ga'
import initProjectList from './init-project-list'

// Lazyload
var layzr = new Layzr({
  threshold: 600
})

// Dynamic Year
document.querySelector('.year').innerText = new Date().getFullYear();
